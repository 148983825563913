<template>
  <div class="sctp-container mg-tb10">
    <div class="mg-b10">
      <el-breadcrumb separator=">">
        <el-breadcrumb-item>首页</el-breadcrumb-item>
        <el-breadcrumb-item>限制登录</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-row :gutter="10" type="flex">
      <el-col :span="18">
        <div class="flex flex-sb sctp-bg-white pad15">
          <div>
            <el-image
              fit="cover"
              :src="limitConfig.imgUrl"
              style="width: 240px;height: 400px;"
            ></el-image>
          </div>
          <div class="pad-lr20">
            <h1>温馨提醒：</h1>
            <div class="fz-18" style="text-indent: 2em;">
              您的账号因{{ limitConfig.info && limitConfig.info.limitReason || '违规' }}被限制登录，请在{{
                dateToString(limitConfig.info && limitConfig.info.limitDate || null,
                  'yyyy年MM月dd日 HH时mm分ss秒')
              }}以后再访问！如有疑问，请提交意见反馈，感谢您的理解。
            </div>
            <div class="sctp-tc" style="margin-top: 40px;">
              <router-link to="/feedback">
                <el-button size="small" type="primary">我要意见反馈</el-button>
              </router-link>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="sctp-bg-white">
          <h3 class="sctp-bold sctp-tc card-title pad-tb15">账号违规情况说明</h3>
          <div class="pad15">
            <template v-for="(reason, index) in limitConfig.reasonList">
              <div
                class="reason-item sctp-ellipsis">
                <router-link :to="`/loginRestrictionsHelp/${reason.id}`">
                  <div>{{ index + 1 }}、{{ reason.reason }}</div>
                </router-link>
              </div>
            </template>
          </div>
        </div>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import {user} from '@/apis/index'

export default {
  name: "loginRestrictions",
  props: ['id'],
  data() {
    return {
      limitConfig: {
        info: null,
        imgUrl: null,
        reasonList: [],
        loadLimitInfo: () => {
          user.limitAccessInfo({
            userId: this.id,
          }).then(res => {
            let {retdata, reasonList, imgUrl} = res;
            if (!retdata || !retdata.limitAccess) {
              this.goPage('/login');
              return;
            }
            this.limitConfig.reasonList = reasonList;
            if (imgUrl) {
              this.limitConfig.imgUrl = imgUrl;
            }
            this.limitConfig.info = retdata;
          })
        }
      }
    }
  },
  beforeMount() {
    this.limitConfig.loadLimitInfo();
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .card-title {
    background-color: #f9f9f9;
    text-align: center;
  }

  .reason-item {
    padding: 5px 0;
    cursor: pointer;

    &:hover {
      background-color: #F8F8F8;
    }

    &:not(:last-child) {
      border-bottom: 1px #ccc dashed;
    }
  }
}
</style>
